import React, { useEffect, useState } from "react";
import styles from "./filters.module.css";
// import styles from './mobileFilters.module.css'
import { findBestFdRate } from "../../utils/data";
import fdData2 from "../../utils/data";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleFilterFD,
  resetFiltersFD,
  setSelectedTenor,
  setIsActive,
} from "../../redux/slice/fdRateDataSlice.js";
function Filters({
  filters,
  handleFilterButtonClick,
  // majorHandler,
  allHandler,
  isAllChecked,
  isMajorChecked,
  selectedFilters,
  clearHandler,
  majorLength,
  // findBestFdRate,
  BanksCounts,
  sliderRange,
  setSliderRange,
  year,
  month,
  setYear,
  setMonth,
  selectedTenor,

  setTenorData,
}) {
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  // const [isActive, setIsActive] = useState(true);
  const isActive = useSelector(
    (state) => state.fdRateData.sliderState.isActive
  );
  const dispatch = useDispatch();
  // console.log("banksVOunt", BanksCounts);

  const handleTopFdRatesDataChange = (event) => {
    console.log("Filter_handleTopFdratesData");
    // setTenor(event.target.value);
    dispatch(setSelectedTenor(event.target.value));
    dispatch(setMonth(0));
    dispatch(setYear(0));
    dispatch(setSliderRange(0));
    dispatch(setIsActive(true));
    setHelperText("");
    setError(false);
  };
  const handleSliderChange = (e) => {
    console.log("slider slider");
    const value = parseInt(e.target.value);
    dispatch(setSelectedTenor(""));
    dispatch(setIsActive(false));
    setHelperText("");
    setError(false);
    const selectedYear = Math.floor(value / 12);
    const selectedMonth = value % 12;
    dispatch(setYear(selectedYear));
    dispatch(setMonth(selectedMonth));
    dispatch(setSliderRange(value));
  };

  const handleYearChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    dispatch(setSelectedTenor(""));
    dispatch(setIsActive(false));
    // console.log("yearhandler");
    if (Number.isInteger(Number(value))) {
      if (Number(value) > 5) {
        dispatch(setYear(5));
        dispatch(setSliderRange(5 * 12 + month));
        setError(true);
        setHelperText(" Year Value must be less than 6");
      } else {
        dispatch(setYear(value));
        const sliderValue = parseInt(value) * 12 + month;
        dispatch(setSliderRange(sliderValue));
        setError(false);
        setHelperText("");
      }
    } else {
      setHelperText(" Year Value must be less than 6");
      setError(true);
    }
  };

  const handleMonthChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    dispatch(setSelectedTenor(""));
    dispatch(setIsActive(false));
    if (!isNaN(value)) {
      if (Number(value) > 11) {
        dispatch(setMonth(11));
        const sliderValue = year * 12 + 11;
        dispatch(setSliderRange(sliderValue));
        setHelperText("Month Value must be less than 12");
        setError(true);
      } else {
        dispatch(setMonth(value));
        const sliderValue = year * 12 + parseInt(value);
        dispatch(setSliderRange(sliderValue));
        setHelperText("");
        setError(false);
      }
    } else {
      setHelperText("Month Value must be less than 12");
      setError(true);
    }
  };

  const isActiveHandler = () => {
    dispatch(setIsActive(false));
    dispatch(setSelectedTenor(""));
    dispatch(setSliderRange(12));
    dispatch(setYear(1));
    // setMonth(1);
  };

  const isActiveAllterms = () => {
    dispatch(setSelectedTenor("All terms"));
    dispatch(setIsActive(true));
    dispatch(setSliderRange(0));
    dispatch(setYear(0));
    dispatch(setMonth(0));
  };

  return (
    <div className={styles.authorsWrap1}>
      <ul className={styles.filters1}>
        <li className={styles.listDiv}>
          <Typography variant="h6" className={styles.filterHeading1}>
            Bank Type{" "}
          </Typography>
          <fieldset>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="top"
                  // className={styles.checkBox1}
                  control={
                    <Radio
                      size="small"
                      className={styles.checkBox1}
                      type="radio"
                      name="acs"
                      // defaultChecked="true"
                      value="Germany"
                      id="Major"
                      checked={isMajorChecked}
                      onChange={allHandler}
                    />
                  }
                  label={`Major ${BanksCounts.majorLength} Banks`}
                />
                {/* <div>
                  <input
                    className={styles.checkBox1}
                    type="radio"
                    name="acs"
                    // defaultChecked="true"
                    value="Germany"
                    id="Major"
                    checked={isMajorChecked}
                    onChange={allHandler}
                  />
                  <label htmlFor="Major">
                    Major {BanksCounts.majorLength} Banks
                  </label>
                </div> */}
                <FormControlLabel
                  // className={styles.checkBox1}
                  control={
                    <Radio
                      size="small"
                      className={styles.checkBox1}
                      type="radio"
                      name="acs"
                      checked={isAllChecked}
                      id="All"
                      onChange={allHandler}
                    />
                  }
                  label={
                    <span>
                      All Banks <small>({BanksCounts["All Banks"]})</small>
                      <span className={styles.clear1} onClick={clearHandler}>
                        clear
                      </span>
                    </span>
                  }
                />
              </RadioGroup>
              {filters.map((category, idx) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      size="small"
                      type="checkbox"
                      checked={selectedFilters.includes(category.filterName)}
                      id={idx}
                      onChange={() =>
                        handleFilterButtonClick(category.filterName)
                      } // only call method by using arrow syntax else it is called at every render and not on click event
                      key={`filters ${idx}`}
                    />
                  }
                  label={
                    <span>
                      {category.filterName}{" "}
                      <small className={styles.banksCount}>
                        ({BanksCounts[`${category.filterName}`]})
                      </small>
                    </span>
                  }
                  sx={{ ml: "15px", mt: "-8px", mb: "5px" }}
                />
              ))}
            </FormControl>
          </fieldset>
        </li>
        <FormControl error={error}>
          <li className={styles.listDiv}>
            <div>
              <fieldset>
                <Typography variant="h6">Show the best rate for: </Typography>
                <div
                  className={!isActive ? styles.disableDiv : styles.enableDiv}
                  onClick={selectedTenor === "" ? isActiveAllterms : null}
                >
                  <span className={styles.subFilterHeading}>
                    A given period:
                  </span>

                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <div className={styles.subTenorDiv}>
                      <FormControlLabel
                        value="top"
                        className={styles.checkBox1}
                        control={
                          <Radio
                            size="small"
                            disabled={selectedTenor === ""}
                            type="radio"
                            name="top"
                            value="All terms"
                            id="All terms"
                            // defaultChecked={true}
                            checked={selectedTenor === "All terms"}
                            onChange={handleTopFdRatesDataChange}
                          />
                        }
                        label="All terms"
                      />
                    </div>
                    {["0 - 1y", "1 - 2y", "2 - 3y", "3 - 5y", "Above 5y"].map(
                      (tenor, index) => (
                        <div key={index} className={styles.subTenorDiv}>
                          <FormControlLabel
                            value={tenor}
                            className={styles.checkBox1}
                            control={
                              <Radio
                                disabled={selectedTenor === ""}
                                type="radio"
                                name="top"
                                value={tenor}
                                id={tenor}
                                checked={selectedTenor === tenor}
                                onChange={handleTopFdRatesDataChange}
                                size="small"
                              />
                            }
                            label={
                              index === 0
                                ? tenor.replace("y", " year")
                                : tenor.replace("y", " years")
                            }
                          />
                        </div>
                      )
                    )}
                  </RadioGroup>
                </div>
              </fieldset>
            </div>
          </li>
          <li>
            <div className={styles.customDivider}>OR</div>
            <div
              className={isActive ? styles.disableDiv : styles.enableDiv}
              onClick={selectedTenor !== "" ? isActiveHandler : null}
            >
              <fieldset>
                <span className={styles.subFilterHeading}>
                  A custom tenor:{" "}
                </span>
                <div className={styles.rangeSlider}>
                  year{" "}
                  <input
                    className={
                      !isActive ? styles.MonthAndYear : styles.MonthAndYearDis
                    }
                    type="text"
                    value={year}
                    style={{ width: "50px", height: "25px" }}
                    onChange={handleYearChange}
                  />
                  {/* Display year error */}
                  {"  "}month{" "}
                  <input
                    className={
                      !isActive ? styles.MonthAndYear : styles.MonthAndYearDis
                    }
                    type="text"
                    value={month}
                    style={{ width: "50px", height: "25px" }}
                    onChange={handleMonthChange}
                  />
                  <FormHelperText>{helperText}</FormHelperText>
                  <Slider
                    // defaultValue={0}
                    disabled={selectedTenor !== ""}
                    aria-label="Default"
                    // valueLabelDisplay="auto"
                    value={sliderRange}
                    min={0}
                    max={60}
                    onChange={handleSliderChange}
                    step={1}
                    id="sliderInput"
                  />
                </div>
              </fieldset>
            </div>
          </li>
        </FormControl>
        {/* <li>
          <button
            type="submit"
            onClick={() => setFilterIsClicked(!filterIsClicked)}
            className={styles.applyFiltersFD}
          >
            {" "}
            Apply Filters{" "}
          </button>
        </li> */}
      </ul>
    </div>
  );
}

export default Filters;
