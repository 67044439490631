import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Image,
  Button,
  Slider,
  CardFooter,
} from "@nextui-org/react";
import { User } from "@nextui-org/user";
import { Divider } from "@nextui-org/react";

function BlogCard({ blog }) {
  const [liked, setLiked] = React.useState(false);

  const [blogDetail, setBlogDetail] = useState({
    imgUrl: "",
    author: "",
    isLoaded: false,
  });

  useEffect(() => {
    const { featured_media, author } = blog;

    const getImageUrl = axios.get(
      `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/media/${featured_media}`
    );

    const getAuthor = axios.get(
      `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/users/${author}`
    );

    Promise.all([getImageUrl, getAuthor])
      .then((res) => {
        console.log("res", res);
        setBlogDetail({
          imgUrl: res[0].data.guid.rendered,
          author: res[1].data.name,
          authorImg: res[1].data.avatar_urls[48],
          isLoaded: true,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });

    console.log("author image", blogDetail.authorImg);
  }, []);

  return (
    <Card
      isBlurred
      className=" bg-transparent dark:bg-default-100/50 max-w-[710px]"
      shadow="none"
    >
      <Link to={`/blog/${blog.id}`}>
        <CardBody>
          <div className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
            <div className="flex flex-col col-span-6 md:col-span-8">
              <div className="flex justify-between items-start">
                <div className="flex flex-col gap-0">
                  <User
                    name={blogDetail.author}
                    description={new Date(blog.date).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                    avatarProps={{
                      src: `${blogDetail.authorImg}`,
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col mt-3 ">
                <h1
                  className="text-2xl font-bold"
                  style={{
                    fontFamily:
                      "sohne, 'Helvetica Neue', Helvetica, Arial, sans-serif",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    lineHeight: "28px",
                    WebkitLineClamp: "1",
                    letterSpacing: "-0.5px",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {" "}
                  {blog.title.rendered}
                </h1>
                <div className="flex justify-between">
                  <p
                    className="text-medium text-foreground/50 "
                    style={{
                      fontFamily:
                        "sohne, 'Helvetica Neue', Helvetica, Arial, sans-serif",
                      lineHeight: "28px",
                      color: "#6B6B6B",
                      fontWeight: "400",
                      letterSpacing: "-0.2px",
                      display: "-webkit-box",
                      WebkitLineClamp: 2, // Limits the content to 2 lines
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                    }}
                    dangerouslySetInnerHTML={{ __html: blog.excerpt.rendered }}
                  />
                </div>
              </div>
            </div>
            <div className="relative col-span-6 md:col-span-4">
              <Image
                alt="Album cover"
                className="object-cover"
                height={200}
                shadow="sm"
                src={blogDetail.imgUrl}
                width="100%"
              />
            </div>
          </div>
        </CardBody>
      </Link>

      {/* <CardFooter className="text-small ">
    <b>91.5</b>
    <p className="text-default-500">Followers</p>
  </CardFooter> */}
      <Divider className="my-1" />
    </Card>
  );
}

export default BlogCard;
