import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./breadcrumbs.module.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function Breadcrumbs({ color }) {
  const breadcrumbs = useBreadcrumbs();
  console.log("breadcrumbs", breadcrumbs);
  return (
    <div
      className={styles.breadcrumbsContainer}
      style={{ backgroundColor: color }}
    >
      <div className={styles.breadcrumbSubCont}>
        <div className={styles.breadcrumbSubDiv}>
          {breadcrumbs.length > 1 && (
            <Link to={"/"} className={styles.breadcrumb}>
              <span>Home </span>
            </Link>
          )}
          {breadcrumbs.map(({ breadcrumb, match }, index) => (
            <Link
              to={
                match.pathname === "/bankDetails"
                  ? "/FDpage"
                  : match.pathname === "/savingsRateDetails"
                  ? "/savingsPage"
                  : match.pathname
              }
              key={match.pathname}
              className={
                match.pathname === window.location.pathname
                  ? styles.breadcrumbActive
                  : styles.breadcrumb
              }
            >
              <span>
                {index !== 0 && "/" ? (
                  <span key={index}>
                    <NavigateNextIcon
                      sx={{
                        color: "#5f6368",
                        marginRight: "5px",
                        fontSize: 17,
                      }}
                    />
                    {breadcrumb.props.children
                      .replaceAll("%20", " ")
                      .replace("BankDetails", "Fixed Deposits")
                      .replace("SavingsRateDetails", "Savings Deposits")
                      .replace("FDpage", "Fixed Deposits")
                      .replace("SavingsPage", "Savings Deposits")
                      .replace("Content", "Learning Centre")
                      .replace("ContactUs", "Contact Us")
                      .replace("Privacy", "Privacy Policy")
                      .replace("TandC", "Terms & Conditions")}{" "}
                  </span>
                ) : null}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Breadcrumbs;
