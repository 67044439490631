import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <div className={styles.navWrapper}>
      <nav className={styles.navbar}>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <div className={styles.menuIcon} onClick={handleShowNavbar}>
              <FontAwesomeIcon
                icon={faBars}
                style={{ width: " 18px", height: "18px" }}
              />
            </div>
            <div onClick={handleClick} className={styles.logo}>
              {/* <div className={styles.logoImg}> <img src="../bankLogos/wanijLogo.png" alt="website logo" /></div> */}
              <h3>Wanij</h3>
            </div>
          </div>
          {showNavbar ? (
            <div
              className={styles.transparentDiv}
              onClick={() => setShowNavbar(false)}
            ></div>
          ) : null}
          <div
            className={`${styles.navElements}  ${showNavbar && styles.active}`}
          >
            <ul onClick={showNavbar && styles.active ? handleShowNavbar : null}>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/FDpage"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Fixed Deposits
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/savingsPage"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Savings Deposits
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blog"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Blog
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contactUs"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Contact Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/content"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Learning Centre
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
