import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { encryptData } from "../../utils/localEncruption";
import { decryptData, decryptAPIData } from "../../utils/localEncruption";

const fetchSavingsFromAPI = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_SAVINGS_RATES_URL_ALL}`
  );
  const encryptedData = await response.text();
  try {
    const decryptedData = await decryptAPIData(
      encryptedData,
      "gsDFQwTwuuOXWgh0JG/En4NtZWbahbacdQtlCkMg9tE="
    );
    return decryptedData;
  } catch (error) {
    console.error("Decryption failed for FD rate:", error);
  }

  return response.json();
};

export const fetchUsingAsyncAwaitWithFetchApi = async (thunkAPI) => {
  const state = thunkAPI.getState();
  const response = await fetch(
    `${process.env.REACT_APP_SAVINGS_RATES_URL}` + state.savingsFullRate.Amount,
    { mode: "cors" }
  );
  const data = await response.text();
  try {
    const decryptedData = await decryptAPIData(
      data,
      "gsDFQwTwuuOXWgh0JG/En4NtZWbahbacdQtlCkMg9tE="
    );
    return decryptedData;
  } catch (error) {
    console.error("Decryption failed for the second API:", error);
  }
};

// Helper function to get data from local storage
export const getSavingsFromLocalStorage = () => {
  const storedData = localStorage.getItem("savingsData");
  const salt = `${process.env.REACT_APP_SALT}`;
  if (!storedData) {
    return null;
  }

  try {
    const decryptedData = decryptData(storedData, salt);
    // console.log("decryptedDataSVINGS", decryptedData);
    return decryptedData;
  } catch (error) {
    return null;
  }
};

// Helper function to save data to local storage
const saveSavingsToLocalStorage = (data) => {
  const salt = `${process.env.REACT_APP_SALT}`;
  const encryptedData = encryptData(data, salt);
  localStorage.setItem("savingsData", encryptedData);
};

// Helper function to get the timestamp when data was last fetched
const getTimestampFromLocalStorage = (key) => {
  return localStorage.getItem(`${key}_timestamp`);
};

// Helper function to save the timestamp when data is fetched
const saveTimestampToLocalStorage = (key) => {
  const timestamp = new Date().getTime();
  localStorage.setItem(`${key}_timestamp`, timestamp);
};

// Define a threshold in milliseconds for data freshness (e.g., 1 hour)
const DATA_FRESHNESS_THRESHOLD = 60 * 60 * 1000; // 1 hour

// Async thunk to fetch savings, considering local storage
export const fetchSavings = createAsyncThunk(
  "fetchSavings",
  async (_, thunkAPI) => {
    const cachedData = getSavingsFromLocalStorage();
    const timestamp = getTimestampFromLocalStorage("savingsData");
    const currentTime = new Date().getTime();

    // Check if data in local storage is stale
    const isData1Stale =
      !timestamp || currentTime - timestamp > DATA_FRESHNESS_THRESHOLD;

    if (!cachedData || isData1Stale) {
      try {
        console.log("Fetching savings data from API...");
        const data = await fetchSavingsFromAPI();

        saveSavingsToLocalStorage(data);
        saveTimestampToLocalStorage("savingsData");

        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
    return cachedData;
  }
);

export const fetchOtherData = createAsyncThunk(
  "savings/fetchOtherData",
  async (_, thunkAPI) => {
    try {
      const data = await fetchUsingAsyncAwaitWithFetchApi(thunkAPI);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const savingsFullRatesSlice = createSlice({
  name: "savingsFullRate",
  initialState: {
    isLoading: false,
    data: getSavingsFromLocalStorage() || {},
    bankData: [],
    selectedFilters: [],
    isError: false,
    Amount: "1000000",
    selectedBanks: [],
    sortedItems: [],
  },

  reducers: {
    toggleFilter: (state, action) => {
      const selectedCategory = action.payload;
      if (state.selectedFilters.includes(selectedCategory)) {
        state.selectedFilters = state.selectedFilters.filter(
          (element) => element !== selectedCategory
        );
      } else {
        state.selectedFilters.push(selectedCategory);
      }
    },
    resetFilters: (state) => {
      state.selectedFilters = [];
    },

    setAmount: (state, action) => {
      state.Amount = action.payload; // Add a reducer for setting amount
    },

    setSelectedBanks: (state, action) => {
      state.selectedBanks = action.payload;
    },

    setSortedItems: (state, action) => {
      state.sortedItems = action.payload;
    },
  },

  extraReducers: (builder) => {
    // Handle fetching savings data
    builder.addCase(fetchSavings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSavings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload; // result of data return by dispatch(fetchSavings());
    });
    builder.addCase(fetchSavings.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });

    // Handle fetchOtherData
    builder.addCase(fetchOtherData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOtherData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bankData = action.payload;
    });
    builder.addCase(fetchOtherData.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
    });
  },
});

export const {
  toggleFilter,
  resetFilters,
  setAmount,
  setSelectedBanks,
  setSortedItems,
} = savingsFullRatesSlice.actions;
export default savingsFullRatesSlice.reducer;
