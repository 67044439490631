import { getDaysFromYearAndMonths } from "./getDaysFromYearAndMonths";

// const BASE_URL1 = "https://backend.keval.in/fdrate_interest/";
// const BASE_URL2 = "https://backend.keval.in/fdrate_all/"
//   // console.log("Base_url", BASE_URL);
//   const fetchUsingAsyncAwaitWithFetchApi = async (BASE_URL) => {
//     try {
//       const response = await fetch(BASE_URL, { mode: "cors" });
//       const data = await response.json();
//       console.log("Response data-->", data);
//       return data;
//       // setLoader(false);
//       // setFormattedAmount("");
//     } catch (error) {
//       // setErrPage(true);
//       console.log("Something went wrong: ", error);
//     }
//   };

//   const fdData2 = []
//   console.log("Fixed rates2 ", fdData2);
//   const fdData = []

const findBestFdRate = (start, end, allOrMajor, fdData2) => {
  const startDs = getDaysFromYearAndMonths(start);
  const endDs = getDaysFromYearAndMonths(end);
  // console.log("start", startDs, "end", endDs);
//   console.log("12 months -> ", getDaysFromYearAndMonths(12));

  const data3 = fdData2.all_fd;
  const data2 = [];

  for (const key in data3) {

    let rate = 0;
    data3[key].map((fd) => {
      if (
        fd.tenorstartday >= startDs &&
        (fd.tenorendday <= endDs || fd.tenorstartday == endDs)
      ) {
        if (rate < fd.fdrate) {
          rate = fd.fdrate;
        }
      }

      return 0;
    });

    if (!allOrMajor) {
      const fdObj = data3[key].filter(
        (item) =>
          item.major_flag === true &&
          item.fdrate === rate &&
          item.tenorstartday >= startDs &&
          (item.tenorendday <= endDs || item.tenorstartday == endDs)
      );
      data2.push(...fdObj);
    } else {
      const fdObj = data3[key].filter(
        (item) =>
          item.fdrate === rate &&
          item.tenorstartday >= startDs &&
          (item.tenorendday <= endDs || item.tenorstartday == endDs)
      );
      data2.push(...fdObj);
    }
  }
  //   console.log("======", data2);
  // seniorAndSuperSeniorCount();
  return data2;
};

const seniorAndSuperSeniorCount = (fdData2) => {
  const data3 = fdData2.all_fd;
  let seniorCount = 0;
  let supSeniorCount = 0;
  for (const key in data3) {
    const seniorflag = data3[key].filter(
      (fd) => fd.addsenior - fd.fdrate != 0
    ).length;
    const supSeniorFlag = data3[key].filter(
      (fd) => fd.addsupsenior - fd.addsenior != 0
    ).length;
    if (seniorflag > 0) seniorCount++;
    if (supSeniorFlag > 0) supSeniorCount++;
  }
  // console.log("seniorFlag", seniorCount);
  // console.log("supSeniorFlag", supSeniorCount);
  return { seniorCount, supSeniorCount };
};
// findBestFdRate();

// export default fdData2;
// export { fdData };
export { findBestFdRate, seniorAndSuperSeniorCount };
