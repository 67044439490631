import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import "./blog.css";
import { useParams } from "react-router-dom";
import { User } from "@nextui-org/user";
import { Divider } from "@nextui-org/react";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { AnimateSharedLayout } from "framer-motion";

function BlogSinglePost() {
  const [blog, setBlog] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      setIsLoading(true);
      try {
        const url = `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/posts/${id}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setBlog(data);
      } catch (e) {
        console.error("Fetching error:", e);
        setError("Failed to fetch blog post. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (isLoading) return <h1>Loading...</h1>;
  if (error) return <h1>{error}</h1>;
  if (!blog) return <h1>No blog post found</h1>;

  const sanitizedContent = DOMPurify.sanitize(blog.content.rendered);

  return (
    <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-background/60 dark:bg-default-100/50 rounded-lg shadow-none overflow-hidden">
        <div className="p-6 sm:p-8 md:p-10">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-5 ">
            {blog.title.rendered}
          </h1>
          <div className="flex justify-between items-start">
            <div className="flex flex-col gap-0 mb-0">
              <User
                name="Jane Doe"
                description={new Date(blog.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
                avatarProps={{
                  src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                }}
              />
            </div>
          </div>
          <Divider className="my-5 mb-2" />
          <div className="flex justify-between items-start">
            <div className="flex mb-0">
              {" "}
              <p className="text-bold  capitalize  mr-5 mb-2">
                <FontAwesomeIcon icon={faThumbsUp} className="text-lg" />
                <span className="ml-0.5 text-sm text-default-400"> 25k</span>
              </p>
              <p className="text-bold  capitalize  mr-3 mb-2">
                <FontAwesomeIcon icon={faComments} className="text-lg" />
                <span className="ml-0.5 text-sm text-default-400"> 100</span>
              </p>
            </div>
            <div className="mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M15.218 4.931a.4.4 0 0 1-.118.132l.012.006a.45.45 0 0 1-.292.074.5.5 0 0 1-.3-.13l-2.02-2.02v7.07c0 .28-.23.5-.5.5s-.5-.22-.5-.5v-7.04l-2 2a.45.45 0 0 1-.57.04h-.02a.4.4 0 0 1-.16-.3.4.4 0 0 1 .1-.32l2.8-2.8a.5.5 0 0 1 .7 0l2.8 2.79a.42.42 0 0 1 .068.498m-.106.138.008.004v-.01zM16 7.063h1.5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-11c-1.1 0-2-.9-2-2v-10a2 2 0 0 1 2-2H8a.5.5 0 0 1 .35.15.5.5 0 0 1 .15.35.5.5 0 0 1-.15.35.5.5 0 0 1-.35.15H6.4c-.5 0-.9.4-.9.9v10.2a.9.9 0 0 0 .9.9h11.2c.5 0 .9-.4.9-.9v-10.2c0-.5-.4-.9-.9-.9H16a.5.5 0 0 1 0-1"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <Divider className="my-10 mt-0" />

          <div
            className="prose prose-sm sm:prose lg:prose-lg mx-auto"
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
        </div>
      </div>
    </div>
  );
}

export default BlogSinglePost;
