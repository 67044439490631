import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fdData } from "../../utils/data";
import styles from "./bankDetail.module.css";
import { lastUpdateDate } from "../../utils/lastUpdateDate";
import { useSelector, useDispatch } from "react-redux";
import { fetchFdRates } from "../../redux/slice/fdRateDataSlice";
import LoadingIndicator from "../../components/LoadingIndicator";
import Chip from "@mui/material/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";

function BankDetails() {
  // const dispatch = useDispatch();
  const { id } = useParams();
  console.log(id);
  const fdData = useSelector((state) => state.fdRateData.data1.all_fd[id]);
  const [filteredItems, setFilteredItems] = useState(fdData);
  console.log("FdDatafullRates", fdData);
  const { isError, isLoading } = useSelector((state) => state.fdRateData);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [maxRate, setMaxRate] = useState(0);
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [rankOrder, setRankOrder] = useState(true);
  const [lodingMessage, setLodingMessage] = useState([]);

  const columns = [
    {
      name: "Tenor",
      accessor: "tenorstartday",
      sortable: true,
      default: false,
      classColumn: "tenor",
    },
    {
      name: "Base Rate",
      accessor: "fdrate",
      sortable: true,
      def: false,
      classColumn: "interestRate",
    },
    {
      name: "Senior Citizen",
      accessor: "addsenior",
      sortable: true,
      def: false,
      classColumn: "interestRate1",
    },
    {
      name: "Super Sr Citizen",
      accessor: "addsupsenior",
      sortable: true,
      def: true,
      classColumn: "interestRate2",
    },
  ];

  const findMaxRate = () => {
    let rate = 0;
    fdData.map((item, index) => {
      if (item.fdrate > rate) {
        rate = item.fdrate;
      }
    });
    setMaxRate(rate);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    // Check if data is already available or needs fetching
    if (!fdData) {
      dispatch(fetchFdRates());
    }
    findMaxRate();
  }, [dispatch, id, fdData]);

  const [specialBankCount, setSpecCount] = useState(
    fdData.filter((item) => item.specialrate === "y").length
  );
  const flag = fdData.filter(
    (item) => item.addsupsenior - item.addsenior !== 0
  );

  console.log("banksDetails", flag.length);
  const [superFlag, setSuperFlag] = useState(flag.length > 0 ? true : false);

  const handleSortingChange = (accessor) => {
    // console.log(accessor);
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    setRankOrder(false);
    // console.log(filteredItems);

    handleSorting(accessor, sortOrder);
  };

  const handleSorting = async (sortField, sortOrder) => {
    // console.log("--...", sortField, sortOrder, fdData);

    console.log(sortField, sortOrder);

    if (sortField) {
      const sorted = [...filteredItems].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        const isNumericA =
          !isNaN(parseFloat(a[sortField])) && isFinite(a[sortField]);
        const isNumericB =
          !isNaN(parseFloat(b[sortField])) && isFinite(b[sortField]);

        // If both values are numeric, perform numeric comparison
        if (isNumericA && isNumericB) {
          const numA = parseFloat(a[sortField]);
          const numB = parseFloat(b[sortField]);
          return (numA - numB) * (sortOrder === "asc" ? 1 : -1);
        }

        // Otherwise, perform string comparison
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });

      setFilteredItems(sorted);
      console.log("sorted--> ", filteredItems);
      // console.log("-->", sorted);
    }
  };

  function transformDuration(duration) {
    // Define regex patterns to match numbers followed by units
    const patterns = {
      yr: /(\d+) yr/,
      mo: /(\d+) mo/,
      d: /(\d+) d/,
    };

    // Function to determine the correct singular or plural form
    function replaceUnit(match, number, unit) {
      // Pluralize the unit if the number is greater than 1
      const pluralizedUnit = number > 1 ? `${unit}s` : unit;
      return `${number} ${pluralizedUnit}`;
    }

    // Perform replacements based on the patterns
    let transformed = duration;
    transformed = transformed.replace(patterns.yr, (match, number) =>
      replaceUnit(match, number, "year")
    );
    transformed = transformed.replace(patterns.mo, (match, number) =>
      replaceUnit(match, number, "month")
    );
    transformed = transformed.replace(patterns.d, (match, number) =>
      replaceUnit(match, number, "day")
    );

    // Remove any extra spaces and return the result
    return transformed.trim();
  }

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading data</p>;
  if (!fdData) return null;

  const { mobile_name, effdate, max_fd_limit } = fdData[0];
  return (
    <>
      <div className={styles.bankListContainer4}>
        <div className={styles.bankListWrapper}>
          <div className={styles.card}>
            <div className={styles.img}>
              <img
                width="50x"
                height="50px"
                src={`../bankLogos/${fdData[0].bcode}.png`}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <div className={styles.textBox}>
              <div className={styles.textContent}>
                <p className={styles.h1}>
                  {mobile_name}
                  {isMobile ? " FD Rates" : " FD Interest Rates"}
                </p>
                {/* <span className={styles.span}>12 min ago</span> */}
              </div>
              <p className={styles.p}>
                <span>
                  This{" "}
                  {fdData[0].type === "NBFC" || fdData[0].type === "Post Office"
                    ? "institution"
                    : "bank"}{" "}
                  last updated its FD rates on: {fdData[0].effdate}.
                  {/* <br></br> Check the {fdData[0].type === "NBFC" || fdData[0].type === "Post Office" ? "institution" : "bank"}  
                    <a
                      rel="stylesheet"
                      target="_blank"
                      href={fdData[0].url}
                      style={{ color: "#0062E3" }}
                    >
                       {" "}website{" "}
                    </a>
                    for more details. */}
                </span>
              </p>
            </div>
          </div>
          {/* <div className={styles.heading4}>
            <h4>
              {" "}
              {name} Fixed Deposit Interest Rates 2024 { name !== "Post Office"? `(Below Rs.${max_fd_limit})` : "(No maximum deposit limit)"}
            </h4>
          </div> */}
          <div className={styles.tableWraper4}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <table className={styles.responsiveTable4}>
                <thead>
                  <tr>
                    {columns.map(
                      (
                        { name, accessor, sortable, def, classColumn },
                        index
                      ) => {
                        const cl = sortable
                          ? sortField === accessor && order === "asc"
                            ? "Up"
                            : sortField === accessor && order === "desc"
                            ? "Down"
                            : "default"
                          : "";
                        // console.log(cl);
                        return (
                          <>
                            {superFlag || !def ? (
                              <th
                                key={index}
                                onClick={() => handleSortingChange(accessor)}
                              >
                                <div className={styles[classColumn]}>
                                  <span>
                                    {" "}
                                    {name === "Base Rate" && isMobile
                                      ? "Rate"
                                      : name}
                                  </span>

                                  <span>
                                    {rankOrder === true && index === 0 ? (
                                      <FontAwesomeIcon
                                        icon={faSortUp}
                                        style={{ color: "007bff" }}
                                        className={styles.arrow}
                                      />
                                    ) : rankOrder === false && index === 0 ? (
                                      <FontAwesomeIcon
                                        icon={
                                          cl === "Up"
                                            ? faSortUp
                                            : cl === "Down"
                                            ? faSortDown
                                            : faSort
                                        }
                                        style={{
                                          color:
                                            cl !== "default"
                                              ? "#007bff"
                                              : "#4a5568",
                                        }}
                                        className={styles.arrow}
                                      />
                                    ) : null}

                                    {cl !== "default" &&
                                    rankOrder === false &&
                                    index !== 0 ? (
                                      <FontAwesomeIcon
                                        icon={
                                          cl === "Up" ? faSortUp : faSortDown
                                        }
                                        style={{ color: "#007bff" }}
                                        className={styles.arrow}
                                      />
                                    ) : index !== 0 ? (
                                      <FontAwesomeIcon
                                        icon={faSort}
                                        style={{ color: "#4a5568" }}
                                        className={styles.arrow}
                                      />
                                    ) : null}
                                  </span>
                                </div>
                              </th>
                            ) : null}
                          </>
                        );
                      }
                    )}
                  </tr>
                </thead>
                <tbody>
                  {/* Mapping through bankData array to render rows */}

                  {filteredItems.map((row, index) => (
                    <tr key={index}>
                      {/* Displaying bank data in respective columns */}
                      {/* <td>{row.rank1}</td> */}
                      <td className={styles.bankName4}>
                        {row.startincl === "lt"
                          ? "Less than"
                          : row.startincl === "gt"
                          ? "Above"
                          : null}{" "}
                        {row.tenorstartday !== row.tenorendday ||
                        row.tenorstart !== row.tenorend
                          ? transformDuration(row.tenorstart)
                          : null}{" "}
                        {row.tenorstartday !== row.tenorendday ||
                        row.tenorstart !== row.tenorend
                          ? " to "
                          : null}
                        {row.endincl === "lt"
                          ? "<"
                          : row.endincl === "gt"
                          ? ">"
                          : null}{" "}
                        {transformDuration(row.tenorend)}
                      </td>
                      <td className={styles.fdRate}>
                        {row.fdrate.toFixed(2)}%
                        {row.fdrate === maxRate ? (
                          <Chip
                            label="Highest"
                            size="small"
                            sx={{
                              ml: "10px",
                              color: "#ffffff",
                              fontSize: "11px",
                              height: "20px",
                              borderRadius: "5px",
                              backgroundColor: "#ad1e23",
                            }}
                          ></Chip>
                        ) : null}
                      </td>
                      <td className={styles.fdRate}>
                        {row.addsenior.toFixed(2)}%
                        {row.fdrate === maxRate ? (
                          <Chip
                            label="Highest"
                            size="small"
                            sx={{
                              ml: "10px",
                              color: "#ffffff",
                              fontSize: "11px",
                              height: "20px",
                              borderRadius: "5px",

                              backgroundColor: "#ad1e23",
                            }}
                          ></Chip>
                        ) : null}
                      </td>
                      {superFlag ? (
                        <td className={styles.fdRate}>
                          {row.addsupsenior.toFixed(2)}%
                          {row.fdrate === maxRate ? (
                            <Chip
                              label="Highest"
                              size="small"
                              sx={{
                                ml: "10px",
                                color: "#ffffff",
                                fontSize: "11px",
                                height: "20px",
                                borderRadius: "5px",
                                style: "none",
                                backgroundColor: "#ad1e23",
                              }}
                            ></Chip>
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <div className={styles.features}>
              {/* <h4>Features and Benefits of {fdData[0].name} FD</h4> */}
              <div className={styles.featuresList}>
                <ul>
                  <li aria-level="1">
                    <span>
                      1. Data source: Official{" "}
                      {fdData[0].type === "NBFC"
                        ? "NBFC"
                        : fdData[0].type === "Post Office"
                        ? "institution"
                        : "bank"}{" "}
                      <a
                        target="_blank"
                        href={fdData[0].url}
                        style={{ color: "#0062E3" }}
                      >
                        {" "}
                        website.
                      </a>
                      {fdData[0].max_fd_limit !== "no"
                        ? ` Rates applicable for callable deposits ${"< "}${
                            fdData[0].max_fd_limit
                          }.`
                        : " Rates applicable for callable deposits with no maximum deposit limit."}
                    </span>
                  </li>
                  <li aria-level="1">
                    <span>
                      {fdData[0].type === "NBFC"
                        ? "2. NBFC deposits are not covered by DICGC insurance of Rs 5 lakhs."
                        : fdData[0].type === "Post Office"
                        ? "2. Post Office deposits don't have DICGC insurance but are backed by the Government of India under the Government Savings Promostion Act, 1873. "
                        : "2. This bank is covered by DICGC insurance up to ₹5 lakhs, including principal and interest amounts."}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BankDetails;
