import React from "react";
import {
  toggleFilter,
  resetFilters,
  setSelectedBanks,
} from "../../redux/slice/savingsFullRatesSlice.js";
import { useDispatch, useSelector } from "react-redux";
import styles from "./mobileFilters.module.css";

function MobileFilters({
  filters,
  // majorHandler,
  allHandler,
  isAllChecked,
  isMajorChecked,
  clearHandler,
  filterIsClicked,
  setFilterIsClicked,
  majorLength,
  BanksCounts,
}) {
  const dispatch = useDispatch();
  const selectedFilters = useSelector(
    (state) => state.savingsFullRate.selectedFilters
  );

  const handleFilterButtonClick = (selectedCategory) => {
    dispatch(toggleFilter(selectedCategory));
  };

  return (
    <div
      className={styles.authorsWrap}
      // onClick={() => setFilterIsClicked(!filterIsClicked)}
    >
      <div
        className={styles.filters2}
        onClick={() => setFilterIsClicked(false)}
      ></div>

      <ul className={styles.filters}>
        <li>
          <span className={styles.filterHeading}>
            {/* <img
              width="16"
              height="16"
              src="../bankLogos/filter2.png"
              alt="filterlogo"
            />{" "} */}
            Bank Type{" "}
          </span>
          <fieldset>
            <div className={styles.checkBox}>
              <input
                type="checkbox"
                name="acs"
                // defaultChecked={isMajorChecked}
                value="Germany"
                id="Major"
                checked={isMajorChecked}
                onChange={allHandler}
              />
              {/* {console.log("---->", isMajorChecked)} */}
              <label htmlFor="Major">Major 25 Banks</label>
            </div>
            <div className={styles.checkBox}>
              <input
                type="checkbox"
                name="acs"
                checked={isAllChecked}
                id="All"
                onChange={allHandler}
              />
              {/* {BanksLength} */}
              <label htmlFor="All">
                All Banks{" "}
                <small className={styles.banksCount}>
                  ({BanksCounts["All Banks"]})
                </small>
              </label>
              <span className={styles.clear} onClick={clearHandler}>
                clear
              </span>
            </div>
            {filters.map((category, idx) => (
              <div className={styles.filtersDiv}>
                <input
                  type="checkbox"
                  // name="acs"
                  // value="Germany"
                  checked={selectedFilters.includes(category.filterName)}
                  id={idx}
                  onChange={() => handleFilterButtonClick(category.filterName)} // only call method by using arrow syntax else it is called at every render and not on click event
                  key={`filters ${idx}`}
                />
                <label htmlFor={idx} className={styles.filterLabel}>
                  {category.filterName}{" "}
                  <small className={styles.banksCount}>
                    ({BanksCounts[`${category.filterName}`]})
                  </small>
                </label>
              </div>
            ))}
          </fieldset>
        </li>
        <li>
          <button
            type="submit"
            onClick={() => setFilterIsClicked(!filterIsClicked)}
            className={styles.applyFilters}
          >
            {" "}
            Apply Filters{" "}
          </button>
          {/* <input
            type="checkbox"
            id="styledCheckbox"
            className={styles.radioStyle}
          />
          <label for="styledCheckbox">Option</label> */}
        </li>
      </ul>
      {/* <ul id="authors" className="authors-list"></ul> */}
    </div>
  );
}

export default MobileFilters;
