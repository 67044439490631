import React, { useState, useRef } from "react";
import styles from "./contactUs.module.css";
import emailjs, { init } from "@emailjs/browser";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

function ContactUs() {
  const [toSend, setToSend] = useState({
    from_name: "", // Pre-fill if desired (optional)
    to_name: "",
    message: "",
    reply_to: "",
  });

  const [emailSent, setEmailSent] = useState(false); // Track email sending status
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [captchaError, setCaptchaError] = useState(false);
  const captchaRef = useRef(null);

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
    setError("");
    setCaptchaError("");
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  init("user_xxxxxxxxxxxxxxxxxxx"); // Replace with your EmailJS user ID
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    if (!validateEmail(toSend.reply_to) || !captchaRef.current.getValue()) {
      if (!captchaRef.current.getValue()) {
        setCaptchaError("*Captcha is required");
      } else {
        setError("*Invalid email address");
      }
    } else {
      try {
        setLoading(true);
        setEmailSent(false);

        const result = await emailjs.send(
          "service_ofrne1j",
          "template_lq6siit",
          toSend,
          "Fg4WmcjSTp4kljUS6"
        );
        console.log(result.text); // Success message
        setEmailSent(true);
        setLoading(false);
        setToSend({
          from_name: "", // Pre-fill if desired (optional)
          to_name: "",
          message: "",
          reply_to: "",
        });

        if (captchaRef.current) {
          captchaRef.current.reset();
        }
      } catch (error) {
        console.error(error.text); // Error message
      }
    }
    // Service ID, Template ID, and Public Key from EmailJS
  };

  return (
    <div className={styles.containerContent}>
      <div className={styles.mainDiv}>
        <div className={styles.textContainer}>
          <h1>
            We're a financial product comparison website, serving you fresh
            data!
          </h1>
          <p>
            Have questions or feedback? Send us an email on{" "}
            <span>
              <a style={{ color: "#1a73e8" }} href="mailto:hi@wanij.com">
                hi@wanij.com
              </a>
            </span>{" "}
            or contact us through the form below:
          </p>
        </div>
        <div className={styles.container}>
          <h2>Contact Us</h2>

          <form ref={form} onSubmit={sendEmail}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              maxlength="50"
              id="name"
              name="to_name"
              onChange={handleChange}
              value={toSend.to_name}
              required
            />
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              id="email"
              name="reply_to"
              onChange={handleChange}
              value={toSend.reply_to}
              required
            />
            <Typography sx={{ color: "red", fontSize: "13px", mb: "15px" }}>
              {error !== "" ? error : null}
            </Typography>
            <label htmlFor="message">Message:</label>

            <textarea
              id="message"
              name="message"
              maxlength="1000"
              onChange={handleChange}
              value={toSend.message}
              required
            ></textarea>
            <ReCAPTCHA
              sitekey="6LfwAB4qAAAAAP0QSG1zrF31YVUWiYqb-GqTvAPx"
              ref={captchaRef}
            />
            <Typography sx={{ color: "red", fontSize: "13px", mb: "15px" }}>
              {captchaError !== "" ? captchaError : null}
            </Typography>
            <div className={styles.sendButton}>
              <LoadingButton
                size="large"
                endIcon={<SendIcon />}
                // onClick={sendEmail}
                loading={loading}
                loadingPosition="end"
                variant="contained"
                type="submit"
                sx={{ mt: "20px" }}
              >
                <span>Send</span>
              </LoadingButton>
              {emailSent && (
                <span className={styles.successMessage}>
                  Email Sent Successfully!
                </span>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
