import React, { useState, useEffect } from "react";
import BlogCard from "./BlogCard";
import LoadingIndicator from "../LoadingIndicator";

function BlogLanding() {
  const [blogs, setBlog] = useState([]);

  const fetchBlog = async () => {
    const response = await fetch(
      "https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/posts"
    );
    const data = await response.json();
    setBlog(data);
    console.log(blogs);
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  if (!blogs)
    return (
      <h1>
        <LoadingIndicator />
      </h1>
    );
  return (
    <div class="grid place-items-center">
      {blogs.map((blog) => (
        <BlogCard key={blog.id} blog={blog} />
      ))}
    </div>
  );
}

export default BlogLanding;
