import axios from "axios";

export const lastUpdateDate = () => {
  let today = new Date();
  console.log("Current Date:", today); // Debug log
  const formattedDate = getPreviousMonday(today);
  console.log("Previous Monday Date:", formattedDate); // Debug log
  return formatDate(formattedDate);

  function getPreviousMonday(date) {
    let currentDate = new Date(
      date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    let currentDay = currentDate.getDay();

    // If today is Monday, return today's date
    if (currentDay === 1) {
      return currentDate;
    }

    // Calculate the previous Monday's date
    let daysToPreviousMonday = currentDay === 0 ? 6 : currentDay - 1;
    let previousMondayDate = new Date(currentDate);
    previousMondayDate.setDate(currentDate.getDate() - daysToPreviousMonday);

    return previousMondayDate;
  }

  function formatDate(date) {
    return date.toLocaleDateString("en-US", {
      weekday: "long", // "Monday"
      year: "numeric", // "2024"
      month: "long", // "July"
      day: "numeric", // "12"
      timeZone: "Asia/Kolkata", // Indian Time Zone
    });
  }
};

export const getCurrentMonthAndYear = () => {
  const today = new Date();
  const monthIndex = today.getMonth(); // Returns month index (0-11)
  const year = today.getFullYear(); // Returns the full year (e.g., 2024)

  // Convert the month index to a human-readable month name
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonth = monthNames[monthIndex];
  return { month: currentMonth, year: year };
};

const { month, year } = getCurrentMonthAndYear();
console.log(`Current Month: ${month}, Current Year: ${year}`); // e.g., "Current Month: July, Current Year: 2024"

export const getPosts = async () => {
  const DEV_TO_URL = "https://dev.to/api/articles";

  try {
    const result = axios
      .get(DEV_TO_URL)
      .query({ username: "Carl-W" })
      .then((res) => {
        return res.body;
      });
    return result;
  } catch (error) {
    console.log("WE HAVE FETCH POST ERROR", error);
  }
};
